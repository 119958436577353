
@tailwind components;
@tailwind utilities;


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    font-family: "Lato", "Helvetica" "Neue", "Arial", "Helvetica", sans-serif !important;
  }

